import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StoreSelect from '../components/StoreSelect';
import FetchPandoApi from '../common/FetchPandoApi';
import moment from 'moment';
import ChartTopL from './subpages/ChartWeekSales'; // 매출
import ChartTopR from './subpages/ChartWeekOrder'; // 주문수

class Home extends Component {
	constructor({ match }){
		super()
		this.state = {
			storeId : match.params.storeId === undefined ? localStorage.defaultStoreId : match.params.storeId,
			ownername : localStorage.ownerName,
			RecordList : [],
			OrdersRec_To : 0,	// 주문수 오늘
			NoticeList : [],
			after : new Date(),
			before : new Date().getDate(6),
			IsSuccess : false,
			loading: false,
			error: false
		}
	}

	componentDidMount() {
		this.ToDayOrderCall();
		this.NoticeCall();
	}
 	
	//▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩ 초기 매장의 Data value ▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩
	ToDayOrderCall = async (e) => {
		let self = this
		try{
			const format = "YYYYMMDD";
			const to_monday = moment(new Date()).format(format);		// 오늘
			const response = await FetchPandoApi()
			.then(function(f){
				//return f.get('/store/'+ self.state.storeId + '/record?after=' + to_monday + '&before=' + to_monday);
				return f.get('/store/'+ self.state.storeId +'/order?after=' + to_monday + '&before=' + to_monday);
			})
			this.setState({ OrdersRec_To : response.data.count })
		} catch (e) {
			console.log("Error",e);
			this.setState({error: e});
			this.setState({ IsSuccess : false });
		}
		this.setState({loading: false});
		return true;
	}

	// 공지사항
	NoticeCall = async (e) => {
		try{
			const response = await FetchPandoApi()
			.then(function(f){
				return f.get('/board/notice');
			})
			this.setState({ NoticeList : response.data.items });
			this.setState({ IsSuccess : true });
		} catch (e) {
			console.log("Error",e);
			this.setState({error: e});
			this.setState({ IsSuccess : false });
		}
		this.setState({loading: false});
		return true;
	}
	//▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩ 초기 매장의 Data value ▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩

	//▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩ 상단 매장 셀렉트박스 선택 ▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩
	storeIdChange = (selectedStoreId) => {
		this.setState({ storeId: selectedStoreId })
		//document.location.href = '/'+selectedStoreId
		//console.log(selectedStoreId)
		this.ToDayOrderCall();
	}
	//▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩ 상단 매장 셀렉트박스 선택 ▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩▩

	render(){
	if (this.state.loading) return <div>로딩중..</div>;
	//if (this.state.error) return <div>에러가 발생했습니다</div>;
	//if (!this.state.IsSuccess) return null;	
		return (
			<div id="contents">
			<div className="top-area flex-space-between mb-30">
				<div className="selbox">
					<StoreSelect onChange={this.storeIdChange} storeId={this.state.storeId}/>
				</div>
			</div>
			<div className="main-txt">
				<p className="txt04">{this.state.ownername} 사장님,<br />AI Call 금일 주문접수 총 <b>{this.state.OrdersRec_To.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>건입니다.</p>
				{/* <p className="txt05 mt-10">판도플랫폼에서 광고 성과는 <b>11.2배</b> 입니다.</p> */}
				{/* <button type="button" className="pop-open tip" data-pop="pop-tip"><span>입금 예정 금액 <i className="far fa-question-circle"></i></span></button> --><!-- 팝업버튼에는 pop-open클래스 추가 & data-pop값과 popup의 id값은 일치 시켜준다 */}
				<Link to="NoticeList" className="absolute-r02"><span><i className="fas fa-headset"></i> 마케팅센터 공지<em className="point">5</em></span></Link>
			</div>
			<div className="box mt-30">
				<div className="wrap-list-noti">
					<ul className="list-noti">
						{this.state.NoticeList.map((d, i) =>
						i <= 4 ?
						<li key={i}>
							<span className="badge type01 p-1">{moment(d.createDate).format('YYYYMMDD') >= moment().day(-5).format('YYYYMMDD') ? '신규' : ''}</span>
							<p className='ml-5'><Link to={`NoticeView/${d.noticeId}`}><span className="txt08">{d.title}</span></Link></p>
							<span className="badge type02">{moment(d.createDate).format('YYYYMMDD') >= moment().day(-5).format('YYYYMMDD') ? 'NEW' : ''}</span>
							<span className="date">{moment(d.createDate).format('YYYY.MM.DD')}</span>
						</li>
						:
						<></>
						)}
					</ul>
					<Link to="NoticeList" className="primary"><span className='fas mt-20'>더 많은 공지 보러가기</span></Link>
					<button type="button" className="notice-toggle btn-arrow01"><span>공지리스트 버튼</span></button>
				</div>
			</div>

			<div className="main-graph row mt-20">
				<div className="flex-1 mt-10">
					<div className="box">
						<ChartTopL storeId={this.state.storeId} /> {/*ChartWeekSales - 매출 */}
					</div>
				</div>
				<div className="flex-1 mt-10">
					<div className="box">
						<ChartTopR storeId={this.state.storeId} /> {/*ChartWeekOrder - 주문수 */}
					</div>
				</div>
			</div>
			{/*
			<div className="main-sec01 row flex-space-between mt-30">
				<div className="flex-1">
					<section className="box">
						<h2 className="tit05">주문늘리기</h2>
						<dl className="dl05">
							<dt>오늘 SMS 전송 대사 그룹</dt>
							<dd>
								<div className="box03">
									<div className="order-increase">
										<i className="fas fa-user-friends"></i>
										<span className="txt08 mt-20"><b>구매 패턴 A그룹</b></span>
									</div>
									<div className="btn-area flex-justify-center mt-20">
										<button type="button" className="btn type07 btn-small" ><span>포장 1,000원 할인</span></button>
										<button type="button" className="btn type07 btn-small" ><span>배달 1,000원 할인</span></button>
									</div>
								</div>
							</dd>
						</dl>
					</section>
				</div>

				<div className="flex-1">
					<section className="box">
						<h2 className="tit05">메뉴순위</h2>
						<dl className="dl05">
							<dt>오늘 가게 메뉴 순위</dt>
							{/ <dd><canvas id="myChart"></canvas></dd> /}
							<dd><ChartCenter /></dd>
						</dl>
					</section>
				</div>
				
				<div className="flex-1">
					<section className="box">
						<h2 className="tit05">메뉴추천</h2>
						<dl className="dl05">
							<dt>주간 브랜드별 최강 치킨</dt>
							<dd>
								<ul className="main-list col-03">
									<li>
										<div className="wrap-tooltip">
											<div className="wrap-img">
												<img src="../images/temp/temp_main01.jpg" alt="" />
											</div>
											<span className="txt08">뿌리고치킨</span>
											<span className="txt05 txt-small">알싸한 땡초의향이 더해진 바삭한 "맵.단,짠" 시즈닝 치킨! <br />&lt;원산지 : 국내산&gt;</span>
											<span className="tooltip on" style={{"top": "-18px", "left": "50%"}}>뿌리고치킨 메뉴 추천!<i className="fas fa-caret-down"></i></span>
										</div>
									</li>
									<li>
										<div className="wrap-tooltip">
											<div className="wrap-img">
												<img src="../images/temp/temp_main02.jpg" alt="" />
											</div>
											<span className="txt08">치토스치킨(콘스프맛)</span>
											<span className="txt05 txt-small">바삭한 치킨 위에 새롭게 돌아온 화이트 콘스프맛 시즈닝이 샤르르~ <br />&lt;원산지 : 국내산&gt;</span>
											<span className="tooltip">치토스치킨(콘스프맛) 메뉴 추천!<i className="fas fa-caret-down"></i></span>
										</div>
									</li>
									<li>
										<div className="wrap-tooltip">
											<div className="wrap-img">
												<img src="../images/temp/temp_main03.jpg" alt="" />
											</div>
											<span className="txt08">치토스치킨(매콤달콤한맛)</span>
											<span className="txt05 txt-small">매콤달콤한 치토스 맛 시즈닝이 사르르~ <br />&lt;원산지 : 국내산&gt;</span>
											<span className="tooltip">치토스치킨(매콤달콤한맛) 메뉴 추천!<i className="fas fa-caret-down"></i></span>
										</div>
									</li>
									{/ <li>
										<div className="wrap-img">
											<img src="../images/temp/temp_main04.jpg" alt="" />
										</div>
										<span className="txt08">강정치킨</span>
										<span className="txt-small">남녀노소 누구나 좋아하는 치킨 메뉴! <br />&lt;원산지 : 국내산&gt;</span>
									</li>
									<li>
										<div className="wrap-img">
											<img src="../images/temp/temp_main05.jpg" alt="" />
										</div>
										<span className="txt08">땡초치킨</span>
										<span className="txt-small">매운맛의 진수! 화끈한 닭 맛을 보여드립니다. <br />&lt;원산지 : 국내산&gt;</span>
									</li>
									<li>
										<div className="wrap-img">
											<img src="../images/temp/temp_main06.jpg" alt="" />
										</div>
										<span className="txt08">후라이드치킨</span>
										<span className="txt-small">바삭바삭~ 소리까지 맛있는 치킨! <br />&lt;원산지 : 국내산&gt;</span>
									</li> /}
								</ul>
							</dd>
						</dl>
					</section>
				</div>
				<div className="flex-1">
					<section className="box">
						<h2 className="tit05">주변 경쟁점포</h2>
						<dl className="dl05">
							<dt>오늘 경쟁점포 1위 메뉴</dt>
							<dd>
								<div className="wrap-tooltip">
									<div className="wrap-img main-jumpo">
										<img src="../images/temp/temp_main_graph.jpg" alt="" />
									</div>
									<span className="tooltip on" style={{"top": "40px", "left": "24%"}}>네네치킨 간장핫치킨 1위<i className="fas fa-caret-down"></i></span>
								</div>
							</dd>
						</dl>
					</section>
				</div>
				/}
			</div>
			{/ 
			<div className="row mt-30">
				<div className="flex-1">
					<div className="box">
						<dl className="dl-main">
							<dt className="flex-space-between">
								<span className="tit04"><a href="#none">요청 처리 현황 ></a></span>
								<span className="txt05">최근 1개월</span>
							</dt>
							<dd>
								<div className="d-flex">
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type01">대기</span>
											<span className="number">0<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type02">진행</span>
											<span className="number">25<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type03">반려</span>
											<span className="number">1<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type04">취소</span>
											<span className="number">2<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type04">승인</span>
											<span className="number">15<em>건</em></span>
										</a>
									</div>
								</div>
							</dd>
						</dl>
					</div>
				</div>
				<div className="flex-1">
					<div className="box">
						<dl className="dl-main">
							<dt className="flex-space-between">
								<span className="tit04"><a href="#none">우리 가게 리뷰 ></a></span>
								<select>
									<option>쌀쌀맞은 닭</option>
									<option>BBQ 치킨</option>
								</select>
							</dt>
							<dd>
								<div className="d-flex">
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type04">전체</span>
											<span className="number">24<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type01">미답변</span>
											<span className="number">15<em>건</em></span>
										</a>
									</div>
									<div className="flex-1">
										<a href="#none">
											<span className="badge01 type03">차단의심</span>
											<span className="number">1<em>건</em></span>
										</a>
									</div>
								</div>
							</dd>
						</dl>
					</div>
				</div>
			</div>
			<div className="row mt-30">
				<div className="flex-1">
					<div className="wrap-img main-bn">
						<a href="#none"><img src="../images/temp/temp04.jpg" alt="" /></a>
					</div>
				</div>
				<div className="flex-1">
					<div className="wrap-img main-bn">
						<a href="#none"><img src="../images/temp/temp04.jpg" alt="" /></a>
					</div>
				</div>
			</div> 
			*/}
			</div>
		);
	};
};

export default Home;