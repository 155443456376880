import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Link } from 'react-router-dom';
import FetchPandoApi from '../../common/FetchPandoApi';
import moment from 'moment';

class ChartWeekSales extends Component {
  constructor(props){
      let labelFormatter = function(value) {
        var val = Math.abs(value);
        if (val >= 1) {
          val = (val / 1).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + " 원";
        } 
        return val;
      };
      super(props);
      this.state = {
        storeId : props.storeId,
        SalesRec_This : [],
        SalesRec_Next : [],        
        options : {
          chart: { 
            width: '100%',
            toolbar: { show: false },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: { enabled: true, delay: 150 },
                dynamicAnimation: { enabled: true, speed: 350 }
            }
          },
          //title: { text: "주간 매출 >", align: "left" },
          stroke: { curve: 'smooth' },
          series: [
            { name: "", data: [] }
          ],
          xaxis: { categories: [ "월", "화", "수", "목", "금", "토", "일" ] },
          yaxis:  {  labels: { formatter: labelFormatter } }
        }
      }
    }

    componentDidMount() {
      this.SalesCall();
    }

    SalesCall = async (e) => {
      let self = this
      try{
        const format = "YYYYMMDD";
        //const this_monday = moment().day(1).format(format);		// 이번주 월요일
        const this_sunday = moment().day(7).format(format);		  // 이번주 일요일
        const next_monday = moment().day(-6).format(format);		// 지난주 월요일
        //const next_sunday = moment().day(-0).format(format);	// 지난주 일요일
  
        const response = await FetchPandoApi()
        .then(function(f){
          return f.get('/store/'+ self.state.storeId + '/record?after=' + next_monday + '&before=' + this_sunday);
        })
  
        // 지난주 기간
        for (let s = 0; s <= 6; s++) {
          self.state.SalesRec_Next.push(response.data.items[s].sales)
        }
  
        // 이번주 기간
        for (let o = 7; o <= 13; o++) {
          self.state.SalesRec_This.push(response.data.items[o].sales)
        }
        
        self.setState({ ...self.state, options : {...self.state.options, series : [
            { name: "이번주", data: self.state.SalesRec_This },
            { name: "지난주", data: self.state.SalesRec_Next }
          ]} 
        })
      } catch (e) {
          console.log("Error",e);
        this.setState({error: e});
          this.setState({ IsSuccess : false });
      }
      this.setState({loading: false});
      return true;
    }

  render() {
    return (
      <div className="app">
        <div>          
          <div className="mixed-chart">
            <div style={{fontSize:"13px", fontWeight:"bold", marginLeft:"10px"}}><Link to="./order">주간 매출 &gt;</Link></div>
            <Chart options={this.state.options} series={this.state.options.series} type="line" height="230"/>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartWeekSales;
