import React from 'react';
//import { Link } from 'react-router-dom';

function StoreSelect(props) {

    const handleStoreSelected = (e) => {
        var selectedStoreId = e.target.value
        localStorage.defaultStoreId = selectedStoreId
        props.onChange(selectedStoreId)
    }

    return(
        <select onChange={handleStoreSelected} value={props.storeId}>					
            {localStorage.storeList && JSON.parse(localStorage.storeList).map(store =>(
                <option key={store.storeId} value={store.storeId}>{store.name}</option>
            ))}
        </select>
    );
};
    
export default StoreSelect;