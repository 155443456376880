import $ from "jquery";
window.$ = window.jQuery = $;

$(document).ready(function () {
	// Tab
	$(document).on("click", ".wrap-tab .tab-mn a", function(){
		var temp = $(this).parent("li").index();
		$(this).parent("li").addClass("on").siblings().removeClass("on");
		$(this).closest(".wrap-tab").find(".tab-contents > li").eq(temp).addClass("on").siblings().removeClass("on");
	});

	// nav toggle
	$(document).on("click", ".mn-toggle", function(){
		$(this).parent(".nav-mn").toggleClass("on");
	});

	// main notice toggle
	$(document).on("click", ".notice-toggle", function(){
		$(this).closest(".wrap-list-noti").toggleClass("on");
	});

	// toggle
	$(document).on("click", ".btn-toggle", function(){
		$(this).closest(".wrap-toggle").toggleClass("on");
	});

	// popup open
	$(document).on("click", ".pop-open", function(){
		var menuTxt = $(this).closest(".secbox").find(".tit04 b").text();
		var menuTxt2 = $(this).closest(".secbox").find(".tit04").text();
		var menuTxt3 = $(this).siblings("span").text();
		var popData = $(this).attr("data-pop");
		$(this).parents().find("#"+popData).addClass("on");
		// 메뉴 관리의 팝업 타이틀 동적 생성
		if ( $(this).attr("data-pop") === "pop-menu-group" ) {
			$("#pop-menu-group .pop-head h1").text('').prepend(menuTxt);
		}
		if ( $(this).attr("data-pop") === "pop-menu-edit" ) {
			$("#pop-menu-edit .pop-head h1").text('').prepend(menuTxt);
		}
		if ( $(this).attr("data-pop") === "pop-option-group" ) {
			$("#pop-option-group .pop-head h1").text('').prepend(menuTxt2);
		}
		if ( $(this).attr("data-pop") === "pop-option-edit" ) {
			$("#pop-option-edit .pop-head h1").text('').prepend(menuTxt2);
		}
		// 정산내역
		if ( $(this).attr("data-pop") === "pop-order-price" ) {
			$("#pop-order-price .pop-head h1").text('').prepend(menuTxt3);
		}
	});

	// popup close
	function popClose(){
		$(this).closest(".popup").removeClass("on");
	};
	$(".pop-close").click(popClose);

	// All popup close
	$(document).on("click", ".pop-close-all", function(){
		$(".popup").removeClass("on");
	});

	// clone & paste
	$(document).on("click", ".btn-copy", function(){
		var copy = $(this).closest(".wrap-copy").find(".copy li:last-child").clone();
		$(this).closest(".wrap-copy").find(".copy").append(copy);
	});

	// delete
	$(document).on("click", ".wrap-copy .btn-del", function(){
		$(this).closest("li").remove();
	});

	// checkbox all
	$(document).on("click", ".chk-all", function(){
		$(this).closest(".wrap-chk02").find("input").prop("checked");
		if($(this).closest(".wrap-chk02").find("input").prop("checked")){
			$(".wrap-chk02 input.chk02").prop("checked",true);
		}
		else {
			$(".wrap-chk02 input.chk02").prop("checked",false);
		}
	});
});