import 'react-app-polyfill/ie11'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './store'
import './index.css';
import * as serviceWorker from './serviceWorker';
import Root from './client/Root';
import { ToastProvider } from 'react-toast-notifications'

React.icons = icons

ReactDOM.render(
  <ToastProvider>
    <Provider store={store}>
      <Root/>
    </Provider>
  </ToastProvider>

  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
