import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//import { signIn } from '../auth/Auth';
import AuthRoute from '../auth/AuthRoute';
import Home from '../pages/Home';

import '../scss/style.scss';
import '../css/style.css';
import '../css/contents.css';
import '../js/common.js';
import '../css/maps.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// Containers
const TheLayout = React.lazy(() => import('../components/TheLayout'));
const Login = React.lazy(() => import('../auth/Login'));
const Register = React.lazy(() => import('../auth/Register'));
const FindId = React.lazy(() => import('../auth/FindId'));
const FindPw = React.lazy(() => import('../auth/FindPw'));

//const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
//const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {

     const [user] = useState(null);
    // const authenticated = user != null;
    // const login = ({ email, password }) => setUser(  
    //   signIn({ email, password })
    //   );

    // const logout = () => setUser(null);

    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
                {/* <Route exact path="/login" name="Login Page" render={props => (<Login authenticated={authenticated} login={login} {...props}/> )} /> */}
                <Route exact path="/login" name="Login Page" render={props => (<Login  {...props}/> )} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/findid" name="Find Id Page" render={props => <FindId {...props}/>} />
                <Route exact path="/findpw" name="Find Pw Page" render={props => <FindPw {...props}/>} />
        
                <AuthRoute  path="/home" render={props => <Home user={user} {...props} />} />
                {/* <AuthRoute authenticated={authenticated} path="/" render={props => <Home user={user} {...props} />} /> */}
                {/*
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />        
                */}

                {/* <TheLayout authenticated={authenticated} /> */}
                <TheLayout  />

              </Switch>
            </React.Suspense>
      </BrowserRouter>
    );
//  }
}
export default App;